import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ToastsContainer } from './components/toasts/toasts.component';
import { HttpErrorInterceptor } from './utils/http-error.interceptor';

@NgModule({
  imports: [CommonModule, NgbToastModule, NgbModalModule],
  exports: [ToastsContainer, AlertModalComponent],
  declarations: [ToastsContainer, AlertModalComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class CommonCoreModule {}
