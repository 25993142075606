import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MappingsService } from '@common/services/mappings.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './core/auth/services/auth.service';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  // previousUrl: string = null;
  // currentUrl: string = null;
  // private _routeScrollPositions: { [url: string]: number }[] = [];
  // translatesReady = false;
  // production = env.production;

  termsUlr = environment.privacy.term;
  policyUlr = environment.privacy.policy;
  coockieUlr = environment.privacy.cookie;

  subs = new Subscription();

  constructor(
    // private router: Router,
    // private urlService: UrlService,
    // private translateService: TranslateService,
    // private metaService: MetaService,
    private route: ActivatedRoute,
    private authService: AuthService,
    // private pixelPartnersService: PixelPartnersService,
    // private spinner: NgxSpinnerService,
    private mappings: MappingsService
  ) {
    // translateService.addLangs(['IT']);
    // translateService.setDefaultLang('IT');
    // translateService.use('IT');
    console.log('_____________________ SGB-WEB VERSION 2 ____________________');
  }

  ngOnInit() {
    // this.spinner.show();

    this.mappings.getMappings().subscribe();

    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    //   this.previousUrl = this.currentUrl;
    //   this.currentUrl = event.url;
    //   // this.urlService.setPreviousUrl(this.previousUrl);
    //   this.pixelPartnersService.loadAdForm();
    // });

    // this.router.events
    //   .pipe(
    //     filter(event => event instanceof NavigationEnd),
    //     pluck('urlAfterRedirects'),
    //     tap((data: string) => this.metaService.updateMeta(data))
    //   )
    //   .subscribe();

    // this.router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
    //   if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
    //     this._routeScrollPositions[prevRouteEvent.url] = window.scrollY;
    //   }
    //   if (currRouteEvent instanceof NavigationEnd) {
    //     document.body.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
    //   }
    // });

    this.subs.add(
      this.route.queryParamMap
        .pipe(
          tap(params => {
            if (params.has('utm_campaign')) {
              this.authService.setReferralDataSessionStorage(params);
            }

            if (params.has('privacy')) {
              switch (params.get('privacy')) {
                case 'terms':
                  this.navigateTo(this.termsUlr);
                  break;
                case 'general':
                  this.navigateTo(this.policyUlr);
                  break;
                case 'cookie':
                  this.navigateTo(this.coockieUlr);
                  break;
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private navigateTo(url) {
    window.open(url, '_balnk');
  }
}
