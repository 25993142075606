import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HeaderUser } from '@common/models/common';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  @Input() homeUrl = '/';
  @Input() user: HeaderUser | null = null;
  @Input() hideLoginBtn = false;

  @Output() openMenu = new EventEmitter<void>();
}
