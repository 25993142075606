import { common } from './common';

export const environment = {
  ...common,
  production: true,
  name: 'dev',
  baseUrl: 'https://api.inplace.notprod.net',
  googleTrackingCode: '',
  pixelTrackingEnabled: false,
  salesforceContact: false,
  dossier: true,
  notificationFrequency: false,
  meanWeeklyHours: true,
  newInterviewFeature: true,
  newVideoInterviewAppointment: true,
  contractSignatureFeature: true,
};
