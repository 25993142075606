export const common = {
  chatbot: {
    tokenChatUser: 'lmrKPgrUG1s.LKdjQwrauf4IbCUGyGPAiKo14UVqsV1s_tO0O-O44zg',
    tokenChatPublic: 'S00P1xnCU9A.CFczZF4P3TvailZd4StsQG-WnP9_tDMfOU68LNd_F4g',
  },
  companyImageURL: './assets/images/client_photo.jpg',
  firebase: {
    apiKey: 'AIzaSyDLPKfH4csRFEXRSiZER3G3cJv2rcXsU7M',
    authDomain: 'inplace-b70a2.firebaseapp.com',
    projectId: 'inplace-b70a2',
    storageBucket: 'inplace-b70a2.appspot.com',
    messagingSenderId: '761585335206',
    appId: '1:761585335206:web:a26889c97ba1837c162d67',
  },
  linkedin: {
    clientId: '86pdwmcxq7msoe',
    clientSecret: '9j2jUNb3QY5L59ui',
    redirectUri: 'https://inplace.notprod.net/auth/login',
  },
  social: {
    facebook: 'https://www.facebook.com/inplacework',
    instagram: 'https://www.instagram.com/inplace.it',
    linkedin: 'https://www.linkedin.com/company/82355133/admin/',
  },
  store: {
    google: 'https://play.google.com/store/apps/details?id=it.sgb.inplacework&gl=IT',
    apple: 'https://apps.apple.com/it/app/inplacework/id6443419772',
  },
  privacy: {
    azienda:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-del-legale-rappresentante-dellazienda/',
    candidato:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-dei-candidati/',
    policy: 'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-privacy/',
    term: 'https://blog.inplace.it/terms-conditions-privacy-policy/termini-e-condizioni/',
    term_pay: 'https://blog.inplace.it/terms-conditions-privacy-policy/termini-e-condizioni/',
    cookie: 'https://blog.inplace.it/terms-conditions-privacy-policy/cookie-policy/',
    policy_and_cookies: 'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-privacy/',
    conversations:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-contenuti-nel-sistema-di-messaggistica/',
    interview:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-nel-corso-del-colloquio/',
    video_img:
      'https://blog.inplace.it/terms-conditions-privacy-policy/autorizzazione-alla-pubblicazione-e-diffusione-di-immagini-video-c-v/',
    signature: {
      generalTerms: 'https://blog.inplace.it/terms-conditions-privacy-policy/fea/',
      privacy:
        'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-di-chi-dovra-sottoscrivere-i-contratti-di-lavoro-mediante-firma-elettronica-avanzata-fea/',
    },
    somministrati:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-dei-dipendenti-somministrati/',
    delegate:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-dei-delegati/',
    contact_form:
      'https://blog.inplace.it/terms-conditions-privacy-policy/informativa-sul-trattamento-dei-dati-personali-inseriti-nelmodulo-contattaci/',
  },
  authorization: {
    delegate: 'https://inplace-external-production.s3.eu-south-1.amazonaws.com/authorization_delegate.pdf',
  },
  elevatorUrl: 'https://services.inplace.it/3d/',
  googleTrackingCode: 'G-8P7VMWKNF3', // empty field to deactivate google analytics
  pixelTrackingCode: '963371867824097',
  salesformContactFormSubmit:
    'https://innovation-connect-612--sit.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D1x0000001nCV',
  salesformContactOrgId: '00D1x0000001nCV',
};
