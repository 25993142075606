import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpRequest } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NbAuthModule } from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { environment } from 'src/environments/environment';
import { AppConfig } from '../app.config';
import { SharedModule } from '../shared/shared.module';
import { acl } from './auth/access-control-list';
import { AUTH_INTERCEPTOR_HEADER, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from './auth/auth-interceptor.service';
import { authenticationOptions } from './auth/authentication-options';
import { AuthenticationGuard } from './auth/guards/authentication-guard.service';
import { AuthorizationGuard } from './auth/guards/authorization-guard.service';
import { NotAuthenticationGuard } from './auth/guards/not-authentication-guard.service';
import { RedirectToPrivateGuard } from './auth/guards/redirect-to-private-guard.service';
import { NbSimpleRoleProvider } from './auth/role-provider.class';
import { AuthService } from './auth/services/auth.service';
import { CookieService } from './auth/services/cookie.service';
import { UserService } from './auth/services/user.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { UtilityService } from './utility.service';

const filterInterceptorRequest = (req: HttpRequest<any>) => {
  return (
    req.url === `${environment.baseUrl}/api/identity/api/login` ||
    req.url === `${environment.baseUrl}/api/identity/api/refresh-token`
  );
};

export const CORE_PROVIDERS = [
  NbSecurityModule.forRoot(acl).providers,
  NotAuthenticationGuard,
  AuthenticationGuard,
  RedirectToPrivateGuard,
  AuthorizationGuard,
  UtilityService,
  AuthService,
  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  { provide: AUTH_INTERCEPTOR_HEADER, useValue: 'Authorization' },
  {
    provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    useValue: filterInterceptorRequest,
  },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: AuthInterceptor,
  //   multi: true,
  // },
  // {
  //   provide: HTTP_INTERCEPTORS,
  //   useClass: ErrorHandlerInterceptor,
  //   multi: true,
  // },
];

@NgModule({
  imports: [CommonModule, SharedModule, HttpClientModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(appConfig: typeof AppConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        UserService,
        CookieService,
        ...CORE_PROVIDERS,
        ...NbAuthModule.forRoot(authenticationOptions(appConfig)).providers,
        // ...LoggerModule.forRoot(appConfig.logger).providers,
        // ...ApiClientModule.forRoot({
        //   backend: appConfig.backend,
        // }).providers,
        // ...TranslateModule.forRoot({
        //   langs: [
        //     {
        //       code: 'it',
        //       isDefault: true,
        //       label: 'Italiano',
        //       translations: IT,
        //     },
        //   ],
        // }).providers,
      ],
    };
  }
}
