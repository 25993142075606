import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { getRolePath } from '@common/models/auth';
import { HeaderUser } from '@common/models/common';
import { HeadService } from '@common/services/head.service';
import { PublicService } from '@common/services/pubblic.service';
import { CommonSharedModule } from '@common/shared.module';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-not-found',
  template: `
    <app-header homeUrl="/" [user]="user"></app-header>

    <main class="py-4 not-found">
      <div class="container text-center">
        <div class="row">
          <div class="col-12">
            <h1>Error 404 not found</h1>
            <p class="lead">Nessuna pagina disponibile</p>
          </div>
          <div class="col-12">
            <h3>Per tornare su inplace...</h3>
            <a class="btn btn-link px-2" [routerLink]="['/']">Torna alla pagina iniziale</a>
          </div>
        </div>
      </div>
    </main>

    <app-footer></app-footer>
  `,
  styles: [
    `
      .not-found {
        height: calc(100vh - 409px);
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, CommonSharedModule],
  providers: [PublicService],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  user: HeaderUser;
  subs = new Subscription();

  title = 'Error 404 not found';
  description = 'Erroro il contento riechiesto non é stato torvato.';

  constructor(
    private head: HeadService,
    private pageService: PublicService
  ) {
    this.head.setTitle(this.title);
    this.head.setDesctiption(this.description);
    this.head.addJsonDl({
      title: this.title,
      description: this.description,
    });
  }

  ngOnInit() {
    this.subs.add(
      this.pageService.user$.subscribe(resp => {
        this.user = resp && {
          name: resp.username,
          impersonated: !!resp.impersonator,
          path: getRolePath(resp.roles[0] as any),
        };
      })
    );
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
