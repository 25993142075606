export enum IsLoginType {
  TRUE = 'LOGIN',
  FALSE = 'REGISTRAZIONE',
}

export type ReferralData = {
  campaign_id?: string;
  is_login?: IsLoginType;
  origin_domain?: string;
  campaign_type?: string;
  campaign_name?: string;
};

export type LoginBody = {
  email: string;
  password: string;
  referralData?: ReferralData;
};

export type LoginResponse = {
  token: string;
  expires: number;
  refreshToken: string;
  otp: boolean;
  resetPwdToken: string;
  userUuid: string;
};

export type SocialLoginResponse = {
  jwtToken: LoginResponse;
  socialResponse: any;
};

export type Impersonator = {
  username: string;
  userId: string;
};

export type AppUser = {
  userId: string;
  username: string;
  roles: Array<string>;
  impersonator?: Impersonator;
};

export type AppProfile = {
  username: string;
};

export enum RoleType {
  COMPANY = 'ROLE_CLIENT',
  WORKER = 'ROLE_WORKER',
  DELEGATE = 'ROLE_DELEGATE',
  IMPERSONATED = 'ROLE_ACTING_DELEGATE',
}

export enum UserType {
  COMPANY = 'azienda',
  WORKER = 'candidato',
  DELEGATE = 'delegato',
}

export const mapRoleToRoute = new Map<RoleType, UserType>([
  [RoleType.COMPANY, UserType.COMPANY],
  [RoleType.WORKER, UserType.WORKER],
  [RoleType.DELEGATE, UserType.DELEGATE],
  [RoleType.DELEGATE, UserType.DELEGATE],
  [RoleType.IMPERSONATED, UserType.COMPANY],
]);

export const getRolePath = (role: RoleType): UserType => mapRoleToRoute.get(role);

export enum AuthProvider {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  DEFAULT = 'DEFAULT',
}

export const getAuthProviders = () => {
  const excluded = [AuthProvider.APPLE, AuthProvider.LINKEDIN, AuthProvider.DEFAULT];
  return Object.values(AuthProvider)
    .filter(val => !excluded.includes(val))
    .map(val => ({ des: val.toLowerCase(), cod: val }));
};

export type SocialLoginBody = {
  provider: AuthProvider;
  token: string;
  referralData?: ReferralData;
};

export enum ClientTypes {
  AZIENDA = 'AZIENDA',
  DITTA_INDIVIDUALE = 'DITTA_INDIVIDUALE',
  PRIVATO = 'PRIVATO',
  CONSULENTE_DEL_LAVORO = 'CONSULENTE_DEL_LAVORO',
}
export enum UserStatus {
  COMPLETO = 'COMPLETO',
  INCOMPLETO = 'INCOMPLETO',
  REGISTRAZIONE_INCOMPLETA = 'REGISTRAZIONE_INCOMPLETA',
  REGISTRAZIONE_INCOMPLETA_SOCIAL = 'REGISTRAZIONE_INCOMPLETA_SOCIAL',
  DISABILITATO = 'DISABILITATO',
}
