import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppStorageService } from './app-storage.service';

export type MethodsParams = {
  endpoint: string;
  params?: { [key: string]: string | number };
  headers?: { [key: string]: string };
  body?: { [key: string]: any };
  options?: {
    observe?: 'body' | 'response' | 'events';
    responseType?: 'json' | 'blob' | 'text' | 'arraybuffer';
  };
  isProtected?: boolean;
};

@Injectable()
export abstract class BaseApiService {
  private readonly baseUrl = environment.baseUrl;
  protected readonly notificationPath = 'api/notification';
  protected readonly publicPath = 'api/public';
  protected readonly searchPath = 'api/search';
  protected readonly proposalPath = 'api/proposal';
  protected readonly clientPath = 'api/client';
  protected readonly workerPath = 'api/worker';
  protected readonly delegatePath = 'api/delegates';
  protected readonly documentPath = 'api/document';
  protected readonly identityPath = 'api/identity';

  constructor(
    protected http: HttpClient,
    protected storage: AppStorageService
  ) {}

  // GET Request Builder
  protected get<T>({ endpoint, params, headers, options, isProtected }: MethodsParams): Observable<T> {
    const opts: any = options ?? {};
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`, {
      headers: this.createHeaders(headers, isProtected),
      params: this.createParams(params),
      ...opts,
    }) as any;
  }

  // POST Request Builder
  protected post<T>({ endpoint, body, params, headers, options, isProtected }: MethodsParams): Observable<T> {
    const opts: any = options ?? {};
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, body, {
      headers: this.createHeaders(headers, isProtected),
      params: this.createParams(params),
      ...opts,
    }) as any;
  }

  // PUT Request Builder
  protected put<T>({ endpoint, body, params, headers, options, isProtected }: MethodsParams): Observable<T> {
    const opts: any = options ?? {};
    return this.http.put<T>(`${this.baseUrl}/${endpoint}`, body, {
      headers: this.createHeaders(headers, isProtected),
      params: this.createParams(params),
      ...opts,
    }) as any;
  }

  // PATCH Request Builder
  protected patch<T>({ endpoint, params, headers, options, isProtected }: MethodsParams): Observable<T> {
    const opts: any = options ?? {};
    return this.http.patch<T>(`${this.baseUrl}/${endpoint}`, {
      headers: this.createHeaders(headers, isProtected),
      params: this.createParams(params),
      ...opts,
    });
  }

  // DELETE Request Builder
  protected delete<T>({ endpoint, body, params, headers, options }: MethodsParams): Observable<T> {
    const opts: any = options ?? {};
    return this.http.delete<T>(`${this.baseUrl}/${endpoint}`, {
      body,
      headers: this.createHeaders(headers),
      params: this.createParams(params),
      ...opts,
    }) as any;
  }

  private createParams(params?: { [key: string]: string | number }) {
    if (!params) {
      return;
    }

    const fromObject = {};

    Object.keys(params).forEach(key => {
      if (typeof params[key] === 'number') {
        fromObject[key] = params[key];
      } else if (params[key]) {
        fromObject[key] = params[key];
      }
    });

    return new HttpParams({ fromObject });
  }

  // Request Headers Builder
  private createHeaders(customHeaders?: { [key: string]: string }, isProtected = true): HttpHeaders {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const token = this.storage.getAccessToken();

    if (isProtected && token) {
      headers = headers.set('authorization', `Bearer ${token}`);
    }

    if (customHeaders) {
      for (const key of Object.keys(customHeaders)) {
        headers = headers.set(key, customHeaders[key]);
      }
    }
    return headers;
  }
}
