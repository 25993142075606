import { Injectable } from '@angular/core';
import { AlertModalComponent } from '@common/components/alert-modal/alert-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export type AlertParams = { title: string; message: string };

export type ToastParam = {
  header: string;
  body: string;
};

export type ToastInfo = ToastParam & {
  class?: string;
  delay?: number;
};
@Injectable({ providedIn: 'root' })
export class AlertService {
  toasts: ToastInfo[] = [];

  constructor(private modal: NgbModal) {}

  show(params: ToastInfo) {
    this.add({ ...params });
  }

  error(params: ToastParam) {
    this.add({ ...params, class: 'bg-red text-light', delay: 8000 });
  }

  success(params: ToastParam) {
    this.add({ ...params, class: 'bg-teal text-light', delay: 8000 });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  confirm(opts: AlertParams) {
    return this.openModal({ ...opts, type: 'confirm' });
  }

  info(opts: AlertParams) {
    return this.openModal({ ...opts, type: 'info' });
  }

  private add(params: ToastInfo) {
    this.toasts.push(params);
  }

  private openModal({ title, message, type }: AlertParams & { type: 'confirm' | 'info' }) {
    const conf: NgbModalOptions = { centered: true };
    const ref = this.modal.open(AlertModalComponent, conf);
    const component: AlertModalComponent = ref.componentInstance;
    const result: Promise<boolean> = ref.result;

    component.title = title;
    component.message = message;
    component.type = type;

    return result;
  }
}
