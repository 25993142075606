import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '@common/models/auth';
import { PaggingRequestParams, SelectOption } from '@common/models/common';
import { NotificationsList, NotificationState } from '@common/models/notifications';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppStorageService } from './app-storage.service';
import { AuthService } from './auth.service';
import { BaseApiService } from './base-api.service';

@Injectable()
export abstract class BasePageService<T = any> extends BaseApiService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected auth: AuthService,
    protected storage: AppStorageService
  ) {
    super(http, storage);
  }

  get profile$(): Observable<T> {
    return this.auth.profile$.pipe(map(resp => resp as unknown as T));
  }

  get user$() {
    return this.auth.user$;
  }

  logout(impersonated = false) {
    if (impersonated) {
      this.auth.deimpersonate().pipe(take(1)).subscribe();
    } else {
      this.auth.logout();
    }
  }

  saveFile({ name, content, type }: { name: string; content: ArrayBuffer; type: string }) {
    const file = new Blob([content], { type });
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');

    link.href = url;
    link.download = name;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }

  documentsToSign(type: UserType) {
    const userPath = this.getUserApiPath(type);
    return this.get<{ link: string }>({ endpoint: `${userPath}/documents/toSign` });
  }

  protected getUserApiPath(type: UserType) {
    switch (type) {
      case 'azienda':
        return this.clientPath;
      case 'candidato':
        return this.workerPath;
      default:
        return this.clientPath;
    }
  }

  protected notifications$(params: { status?: NotificationState } & PaggingRequestParams) {
    return this.get<NotificationsList>({ endpoint: `${this.notificationPath}/view/list`, params: { ...params } });
  }

  protected ccnlCodes$() {
    return this.get<SelectOption[]>({
      endpoint: `${this.publicPath}/api/v1/ccnl`,
    });
  }
}
