import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonCoreModule } from '@common/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PixelModule } from 'ngx-pixel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { PrivacyComponent } from './pages/privacy/privacy.component';

registerLocaleData(localeIt);

// const TAIGA_MODULES = [TuiRootModule, TuiDialogModule];
const FIREBASE_MODULES = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAuthModule,
  // AngularFirestoreModule,
  // AngularFireStorageModule,
  // AngularFireDatabaseModule,
];

export function httpLoaderFactory(http: HttpClient) {
  const staticL10n = new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
  // const dynamicL10n = new TranslateHttpLoader(http, `${environment.baseUrl}/api/l10n/`, '/');
  return {
    getTranslation(lang: string): Observable<any> {
      // const o1 = staticL10n.getTranslation(lang);
      // const o2 = dynamicL10n.getTranslation(lang).pipe(catchError(() => of({})));
      // return combineLatest([o1, o2]).pipe(map(([o3, o4]) => Object.assign({}, o3, o4)));
      return staticL10n.getTranslation(lang);
    },
  };
}

let tackModules = [];

if (environment.production) {
  tackModules = [
    NgxGoogleAnalyticsModule.forRoot(
      environment.googleTrackingCode,
      null,
      'https://googletagmanager.com/gtm.js?id=' + environment.googleTrackingCode
    ),
    NgxGoogleAnalyticsRouterModule,
    PixelModule.forRoot({ enabled: environment.pixelTrackingEnabled, pixelId: environment.pixelTrackingCode }),
  ];
}

@NgModule({
  declarations: [AppComponent, PrivacyComponent],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    // ...TAIGA_MODULES,
    ...FIREBASE_MODULES,
    // ToastrModule.forRoot({
    //   timeOut: 3000,
    //   closeButton: true,
    // }),
    // ThemeModule.forRoot(),
    CoreModule.forRoot(AppConfig.extend(null)),
    CommonCoreModule,
    NgbModule,
    // SharedModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: httpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
    ...tackModules,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
