import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@common/services/alert.service';
import { AuthService } from '@common/services/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshSubject = new BehaviorSubject<null | string>(null);

  constructor(
    private auth: AuthService,
    private alert: AlertService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 && !req.url.includes('login')) {
          return this.handleTokenError(err, req, next);
        }
        return this.handleErrorMessage(err);
      })
    );
  }

  private handleTokenError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes('refresh-token')) {
      this.isRefreshing = false;
      this.auth.logout();
      return throwError(err);
    } else if (this.isRefreshing) {
      return this.refreshSubject.pipe(
        filter(res => res !== null),
        switchMap(token => next.handle(this.cloneRequest(req, token)))
      );
    } else {
      this.isRefreshing = true;
      return this.auth.refreshToken().pipe(
        switchMap(resp => {
          this.isRefreshing = false;
          this.refreshSubject.next(resp.token);
          return next.handle(this.cloneRequest(req, resp.token));
        })
      );
    }
  }

  private handleErrorMessage(err: HttpErrorResponse) {
    const { keyErrorMessage, response } = err.error ?? {};

    let message = 'Qualcosa é andato storto nel server riprovare piú tardi.';
    let title = 'Errore';

    if (err.status === 401) {
      message = 'Credenziali non valide.';
    } else if (err.status === 403) {
      message = 'Non hai i permessi per effettuare questa azione.';
    }

    switch (keyErrorMessage) {
      case 'WHITENET_EXCEPTION':
        message = `Non è stato possibile procedere con l'operazione richiesta<br/> ${response.errorDescription}`;
        break;

      case 'ACTIVE_CONTRACTS':
        title = 'ATTENZIONE';
        message = `Non è possibile cancellare il tuo profilo in quanto è presente un contratto attivo. <br/> Per chiarimenti contatta support@inplace.it.`;
        break;

      case 'MISSING_CCNL':
        message = `Non hai i permessi per effettuare questa azione.`;
        break;

      case 'FORBIDDEN':
        title = response.errorDescription;
        message = `Non hai i permessi per effettuare questa azione.`;
        break;
    }

    this.alert.error({ header: title, body: message });
    return throwError(err);
  }

  private cloneRequest(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
