import { Component } from '@angular/core';
import { AlertService, ToastInfo } from '@common/services/alert.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ng-container *ngFor="let toast of toasts">
      <ngb-toast
        [ngClass]="toast.class ? toast.class : 'bg-white'"
        [header]="toast.header"
        [autohide]="true"
        [delay]="toast.delay || 5000"
        (hiddden)="remove(toast)">
        {{ toast.body }}
      </ngb-toast>
    </ng-container>
  `,
  host: { class: 'toast-container position-fixed top-0 end-0 p-3', style: 'z-index: 1200' },
})
export class ToastsContainer {
  toasts: ToastInfo[];

  constructor(private alert: AlertService) {
    this.toasts = this.alert.toasts;
  }

  remove(toast) {
    this.alert.remove(toast);
  }
}
