import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStorageService } from './app-storage.service';
import { AuthService } from './auth.service';
import { BasePageService } from './base-page.service';

@Injectable()
export class PublicService extends BasePageService {
  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected auth: AuthService,
    protected storage: AppStorageService
  ) {
    super(router, http, auth, storage);
  }

  getCandidates(params: { [key: string]: string | number }) {
    return this.get({ endpoint: `${this.publicPath}/search/candidates`, params });
  }

  getAnnouncements(params: { [key: string]: string | number }) {
    return this.get({ endpoint: `${this.searchPath}/${this.publicPath}/v1/search`, params });
  }

  getCandidatesSeo(params: { [key: string]: string | number }) {
    return this.get({ endpoint: `${this.publicPath}/search/candidates/seo`, params });
  }

  getAnnouncementsSeo(params: { [key: string]: string | number }) {
    return this.get({ endpoint: `${this.searchPath}/${this.publicPath}/v1/search`, params });
  }
}
