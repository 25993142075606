import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectOption } from '@common/models/common';
import { FullTimeWorkEnum } from '@common/models/company';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AppStorageService } from './app-storage.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class MappingsService extends BaseApiService {
  private countries = new BehaviorSubject(null);
  private municipalities = new BehaviorSubject(null);
  private zipCodes = new BehaviorSubject(null);
  private provinces = new BehaviorSubject(null);
  private regions = new BehaviorSubject(null);
  private sectors = new BehaviorSubject(null);
  private nationalities = new BehaviorSubject(null);
  private province2region = new BehaviorSubject(null);
  private province2municipalities = new BehaviorSubject(null);
  private hourlySalaryRange = new BehaviorSubject(null);
  private educationLevels = new BehaviorSubject(null);
  private languages = new BehaviorSubject(null);
  private languageLevels = new BehaviorSubject(null);

  constructor(
    protected http: HttpClient,
    protected storage: AppStorageService
  ) {
    super(http, storage);
  }

  fullTimeWorkOptions = [
    { cod: FullTimeWorkEnum.FULL_TIME, des: 'Full Time' },
    { cod: FullTimeWorkEnum.PART_TIME, des: 'Part Time' },
  ];

  notifyOptions = [
    { cod: 'PUSH', des: 'Si' },
    { cod: 'OFF', des: 'No' },
    { cod: 'DAILY', des: 'Recap giornaliero' },
  ];

  genderOptions = this.mapOptions({ MASCHIO: 'MASCHIO', FEMMINA: 'FEMMINA' });

  getMappings() {
    return this.get({ endpoint: `api/l10n/IT/`, isProtected: false }).pipe(
      tap((resp: any) => {
        const { MAPPINGS } = resp;
        this.countries.next(MAPPINGS.countries);
        this.municipalities.next(MAPPINGS.municipalities);
        this.zipCodes.next(MAPPINGS.mun2zipcode);
        this.provinces.next(MAPPINGS.provinces);
        this.regions.next(MAPPINGS.regions);
        this.sectors.next(MAPPINGS.sectors);
        this.nationalities.next(MAPPINGS.nationalities);
        this.province2region.next(MAPPINGS.province2region);
        this.province2municipalities.next(MAPPINGS.mun2province);
        this.hourlySalaryRange.next(MAPPINGS.hourlySalaryRange);
        this.educationLevels.next(MAPPINGS.educationlevels);
        this.languages.next(MAPPINGS.languages);
        this.languageLevels.next(MAPPINGS.languagelevels);
      })
    );
  }

  getCountries() {
    return this.countries.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getRegions() {
    return this.regions.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  mapRegion(code: string) {
    if (!this.regions.value) {
      return;
    }

    return this.capitalize(this.regions.value[code]);
  }

  getProvinces(region: string): Observable<SelectOption[]> {
    return combineLatest([this.provinces.asObservable(), this.province2region.asObservable()]).pipe(
      filter(resp => !!resp[0] && !!resp[1]),
      map(resp => {
        const filtered = this.getFilteredVals(resp[1], region);
        return this.getFilteredOptions(resp[0], filtered);
      })
    );
  }

  mapProvince(code: string) {
    if (!this.provinces.value) {
      return;
    }

    return this.capitalize(this.provinces.value[code]);
  }

  getMunicipalities(province: string) {
    return combineLatest([this.municipalities.asObservable(), this.province2municipalities.asObservable()]).pipe(
      filter(resp => !!resp[0] && !!resp[1]),
      map(resp => {
        const filtered = this.getFilteredVals(resp[1], province);
        return this.getFilteredOptions(resp[0], filtered);
      })
    );
  }

  mapMunicipality(code: string) {
    if (!this.municipalities.value) {
      return;
    }

    return this.capitalize(this.municipalities.value[code]);
  }

  getSectors() {
    return this.sectors.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getNationalities() {
    return this.nationalities.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getHourlySalaryRange() {
    return this.hourlySalaryRange.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getEducationLevels() {
    return this.educationLevels.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getLanguages() {
    return this.languages.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  getLanguageLevels() {
    return this.languageLevels.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => this.mapOptions(resp))
    );
  }

  searchRegion(term: string) {
    return this.regions.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => {
        const result = this.filterByTerm(Object.entries(resp), term);
        return this.mapOptions(result);
      })
    );
  }

  searchMunicipality(term: string) {
    return this.municipalities.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => {
        const result = this.filterByTerm(Object.entries(resp), term);
        return this.mapOptions(result);
      })
    );
  }

  typeHeadMunicipality(term: string) {
    return this.municipalities.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => {
        const result = this.searchByTerm(Object.entries(resp), term);
        return this.mapOptions(result);
      })
    );
  }

  searchSector(term: string) {
    return this.sectors.asObservable().pipe(
      filter(resp => !!resp),
      map(resp => {
        const result = this.filterByTerm(Object.entries(resp), term);
        return this.mapOptions(result);
      })
    );
  }

  mapToUrlParm(term) {
    if (term) {
      return term.replaceAll(' ', '-').replaceAll('_', '-').replaceAll("'", '-').replaceAll('/', '-').toLowerCase();
    }

    return term;
  }

  private getFilteredVals(values: { [key: string]: string }, val: string) {
    return Object.entries(values).reduce((items: string[], value: string[]) => {
      if (value[1] === val) {
        items.push(value[0]);
      }
      return items;
    }, []);
  }

  private getFilteredOptions(values: { [key: string]: string }, filteres: string[]) {
    return Object.keys(values).reduce((items: SelectOption[], key: string) => {
      if (filteres.includes(key)) {
        items.push({ des: this.capitalize(values[key]), cod: key });
      }
      return items;
    }, []);
  }

  private filterByTerm(items, term) {
    return items.reduce((res, [key, val]: [string, string]) => {
      if (this.mapToUrlParm(val) === this.mapToUrlParm(term)) {
        res[key] = val;
      }
      return res;
    }, []);
  }

  private searchByTerm(items, term) {
    return items.reduce((res, [key, val]: [string, string]) => {
      if (this.mapToUrlParm(val).includes(this.mapToUrlParm(term))) {
        res[key] = val;
      }
      return res;
    }, []);
  }

  private mapOptions(values: { [key: string]: string }): SelectOption[] {
    return Object.keys(values).map(key => ({ des: this.capitalize(values[key]), cod: key }));
  }

  private capitalize(term) {
    return String(term).charAt(0).toUpperCase() + String(term).slice(1).toLowerCase();
  }
}
