import { Routes } from '@angular/router';
import { AuthGuard } from '@common/guards/auth.guard';
import { RoleType } from '@common/models/auth';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'azienda',
    loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
  },
  {
    path: 'candidato',
    loadChildren: () => import('./workers/workers.module').then(m => m.WorkersModule),
  },
  {
    path: 'chi-siamo',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'supporto',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
  },
  {
    path: 'registrazione',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'private',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'candidato',
        data: { role: RoleType.WORKER },
        loadChildren: () => import('./worker-private/worker.module').then(m => m.WorkerPrivateModule),
      },
      {
        path: 'azienda',
        data: { role: RoleType.COMPANY },
        loadChildren: () => import('./company-private/company.module').then(m => m.CompanyPrivateModule),
      },
      {
        path: 'delegato',
        data: { role: RoleType.DELEGATE },
        loadChildren: () => import('./delegate/delegate.module').then(m => m.DelegateModule),
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
