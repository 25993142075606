import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ParamMap, Router } from '@angular/router';
import { AlertService } from '@common/services/alert.service';
import {
  NbAuthJWTToken,
  NbAuthResult,
  NbAuthService,
  NbAuthTokenParceler,
  NbTokenService,
  NB_AUTH_OPTIONS,
  NB_AUTH_STRATEGIES,
} from '@nebular/auth';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import 'firebase/auth';
import firebase from 'firebase/compat/app';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgxSpinnerService } from 'ngx-spinner';
// import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of as observableOf, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import { RegisterService } from 'src/app/pages/auth/services/register.service';
import { AnnouncementsService } from 'src/app/pages/private/pages/company/pages/my-announcements/pages/announcements/services/announcements.service';
import { CandidatesService } from 'src/app/pages/private/pages/company/pages/my-announcements/pages/candidates/services/candidates.service';
import { HeaderService } from 'src/app/shared/components/header/header.service';
import { PrivacyDialogComponent } from 'src/app/shared/components/privacy-dialog/privacy-dialog.component';
import { environment } from 'src/environments/environment';
import { ProviderEnum } from '../enum/provider.enum';
import { StatoEnum } from '../enum/stato.enum';
import { RegisterCompanyRequest, RegisterCompanyResponse } from '../interfaces/company.model';
import { ActivateDelegateRequest } from '../interfaces/delegate.model';
import { role2route, RoleType } from '../interfaces/role.model';
import {
  RegisterWorkerProfileRequest,
  RegisterWorkerRequest,
  RegisterWorkerResponse,
  WorkerStatus,
} from '../interfaces/worker.model';
import { ActionType, ReferralData, referralQueryParams, referralSessionStorageKeys } from '../referral-params.list';
import { UserRoleInfo, UserService } from './user.service';

export interface JwtTokenResponse {
  token: string;
  expires: number;
  refreshToken: string;
  otp: boolean;
  resetPwdToken: string;
  userUuid: string;
}
export interface SocialLoginResponse {
  jwtToken: JwtTokenResponse;
  socialResponse: SocialResponse;
}
export interface SocialResponse {
  token: string;
  name: string;
  surname: string;
  email: string;
}

@Injectable()
export class AuthService extends NbAuthService {
  privacyLabel = 'Privacy';

  constructor(
    private apiClient: ApiClient,
    private userService: UserService,
    protected tokenService: NbTokenService,
    @Inject(NB_AUTH_STRATEGIES) protected strategies,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning,
    private parceler: NbAuthTokenParceler,
    // private toastrService: ToastrService,
    private alert: AlertService,
    // private i18n: TranslateService,
    private loading: NgxSpinnerService,
    private http: HttpClient,
    private dialogService: TuiDialogService,
    private injector: Injector,
    private registerService: RegisterService,
    private gaService: GoogleAnalyticsService,
    private headerService: HeaderService,
    private candidatesService: CandidatesService,
    private announcementsService: AnnouncementsService
  ) {
    super(tokenService, strategies);
  }

  login(userData: { email: string; password: string }) {
    return this.authenticate('email-password', {
      email: userData.email,
      password: userData.password,
      referralData: { ...this.getReferralDataSessionStorage(ActionType.LOGIN) },
    }).pipe(
      tap((result: NbAuthResult) => {
        if (!result.isSuccess()) {
          return;
        }
        this.userService.setIdentityFromClaims(result.getToken().getPayload());
      }),
      tap((result: NbAuthResult) => {
        if (!result.isSuccess()) {
          return;
        }
        /**
         * If isOtp is true the user should reset-psw
         * To reset psw the user should be logged out
         * ResetPwdToken is passed as query param to reset-psw page
         */
        if (result.getResponse().body.otp) {
          localStorage.removeItem('auth_app_token');
          this.router.navigate(['auth', 'reset-psw'], {
            queryParams: {
              tkn: result.getResponse().body.resetPwdToken,
              usrtype: 'WKR',
            },
          });
        }
      }),
      /**
       * If isOtp flow can be stopped here
       */
      filter((result: NbAuthResult) => {
        if (result.isSuccess() && result.getResponse().body.otp) {
          return false;
        }
        return true;
      }),
      /**
       * if not isOtp, standard flow can continue
       */
      switchMap((result: NbAuthResult) => {
        if (result.isSuccess()) {
          localStorage.setItem('refresh-token', result.getResponse().body.refreshToken);
          return this._fetchUserRoleInfo(result);
        }
        return observableOf({ result, user: null });
      })
    );
  }

  logout(): Observable<NbAuthResult> {
    this.tokenService.clear().subscribe();
    if (localStorage.getItem('refresh-token')) {
      localStorage.removeItem('refresh-token');
    }
    this.userService.onLogout();
    this.headerService.onLogout();
    this.candidatesService.onLogout();
    this.announcementsService.onLogout();
    // TODO logout
    this.firebaseLogout();
    return observableOf(new NbAuthResult(true));
  }

  activateDelegate(req: ActivateDelegateRequest): Observable<UserRoleInfo> {
    return this.apiClient.request('activateDelegate', req);
  }

  impersonateClient(clientId: number): Observable<UserRoleInfo> {
    return this.apiClient.request<JwtTokenResponse>('impersonateClient', null, null, { clientId }).pipe(
      map(resp => [this.getStrategy('email-password').createToken<NbAuthJWTToken>(resp.token), resp.refreshToken]),
      tap(([token, refreshToken]) => {
        this.tokenService.set(token as NbAuthJWTToken);
      }),
      tap(([token, refreshtoken]) => {
        this.userService.setIdentityFromClaims((token as NbAuthJWTToken).getPayload());
      }),
      switchMap(([token, refreshToken]) => {
        localStorage.setItem('refresh-token', refreshToken as string);
        return this.userService.getUserRoleInfo().pipe(
          tap(user => {
            this.userService.saveUserRoleInfo(user);
          })
        );
      })
    );
  }

  deimpersonateClient(): Observable<UserRoleInfo> {
    return this.apiClient.request<JwtTokenResponse>('deimpersonateClient').pipe(
      map(resp => [this.getStrategy('email-password').createToken<NbAuthJWTToken>(resp.token), resp.refreshToken]),
      tap(([token, refreshToken]) => {
        this.tokenService.set(token as NbAuthJWTToken);
      }),
      tap(([token, refreshtoken]) => {
        this.userService.setIdentityFromClaims((token as NbAuthJWTToken).getPayload());
      }),
      switchMap(([token, refreshToken]) => {
        localStorage.setItem('refresh-token', refreshToken as string);
        return this.userService.getUserRoleInfo().pipe(
          tap(user => {
            this.userService.saveUserRoleInfo(user);
          })
        );
      })
    );
  }

  // Sign in with Google
  GoogleAuth() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return this.AuthLogin(provider, ProviderEnum.GOOGLE);
  }

  // Sign in with Facebook
  FbAuth() {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    return this.AuthLogin(provider, ProviderEnum.FACEBOOK);
  }

  // Sign in with Apple
  AppleAuth() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return this.AuthLogin(provider, ProviderEnum.APPLE);
  }

  // Sign in with LinkedIn
  LinkedInAuth() {
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.linkedin.clientId}&redirect_uri=${environment.linkedin.redirectUri}&scope=r_emailaddress%20r_liteprofile&state=VD70shiOOcwGNNPbbCU64`,
      '_self'
    );
  }

  // TODO: remove if new method
  oldLinkedInLogin(accessToken: string) {
    if (sessionStorage.getItem('temp_auth_app_token')) {
      sessionStorage.removeItem('temp_auth_app_token');
    }
    return this.socialLogin(accessToken, ProviderEnum.LINKEDIN).pipe(
      finalize(() => this.loading.hide()),
      catchError((err: HttpErrorResponse) => {
        if (err.error?.keyErrorMessage && err.error?.keyErrorMessage === 'USER_NOT_REGISTERED') {
          /**
           * If the user is not registered, should be redirected with provider info
           */
          const userInfo = err.error?.response?.errorDescription
            ? JSON.parse(err.error?.response?.errorDescription)
            : null;
          sessionStorage.setItem('temp_auth_app_token', userInfo.token);
          this.dialogService
            .open(new PolymorpheusComponent(PrivacyDialogComponent, this.injector), {
              dismissible: false,
              size: 'l',
              label: this.privacyLabel,
            })
            .subscribe((outcome: any) => {
              if (outcome) {
                this.router.navigate(['auth', 'register', 'worker'], {
                  queryParams: {
                    email: window.btoa(userInfo?.email),
                    firstName: userInfo?.name,
                    lastName: userInfo?.surname,
                    provider: ProviderEnum.LINKEDIN,
                  },
                });
              }
            });
        }
        return throwError(err);
      }),
      /**
       * If response contains no token, flow should be stopped
       */
      filter((payload: { token: string; expires: string; refreshToken: string; userUuid: string }) => {
        if (payload && payload.token) {
          // Google Analytics
          this.gaService.gtag('login', {
            user_type: 'worker', // it must be either <company> or <worker>
            user_id: '',
            method: 'linkedin', // it must be <facebook> or <google> or <email> or <linkedin> or <apple>
          });
          return true;
        }
        this.firebaseLogout();
        return false;
      }),
      /**
       * Mechanical login (login without using Nebular API)
       */
      mergeMap((payload: { token: string; expires: string; refreshToken: string }) => {
        const token = this.getStrategy('email-password').createToken<NbAuthJWTToken>(payload.token);
        return this.tokenService.set(token).pipe(
          tap(() => {
            const raw = this.parceler.wrap(token);
            localStorage.setItem('auth_app_token', raw);
            localStorage.setItem('refresh-token', payload.refreshToken);
          })
        );
      }),
      /**
       * Handle user role
       */ mergeMap(() => {
        return this.userService.getUserRoleInfo().pipe(
          tap(user => {
            this.userService.saveUserRoleInfo(user);
            if (user.stato === StatoEnum.REGISTRAZIONE_INCOMPLETA) {
              if (sessionStorage.getItem('temp_auth_app_token')) {
                sessionStorage.removeItem('temp_auth_app_token');
              }
              /*
               ** ARCA_USERS
               ** handle Arca users with REGISTRAZIONE_INCOMPLETA
               ** cancel login removing token from localStorage
               ** add token to sessionStorage to retrieve from registration
               */
              this.dialogService
                .open(new PolymorpheusComponent(PrivacyDialogComponent, this.injector), {
                  dismissible: false,
                  size: 'l',
                  label: this.privacyLabel,
                })
                .subscribe((outcome: any) => {
                  if (outcome) {
                    sessionStorage.setItem('auth_app_token', localStorage.getItem('auth_app_token'));
                    sessionStorage.setItem('refresh-token', localStorage.getItem('refresh-token'));
                    this.router.navigate(['auth', 'register', 'worker'], {
                      queryParams: {
                        step: 'second',
                        incompleto: 'true',
                      },
                    });
                  }
                });
            }
          })
        );
      }),
      /**
       * If user status is different from REGISTRAZIONE_INCOMPLETA
       * the flow can continue
       */
      filter(user => {
        if (user.stato !== StatoEnum.REGISTRAZIONE_INCOMPLETA) {
          return true;
        }
        return false;
      }),
      /**
       * If sessionStorage contains announcementId, the user has visited FV to reach an announcement detail
       * To perform "Application" the user should be logged in
       * Now the user can be redirected to the private page where the user can apply
       */
      tap(() => {
        const announcementId = localStorage.getItem('announcementId');
        if (announcementId) {
          localStorage.removeItem('announcementId');
          this.router.navigate(['private', 'candidato', 'cerca-annunci', 'search-detail', announcementId]);
        } else {
          this.router.navigate(['private', 'candidato']);
        }
      })
    );
  }

  getLinkedInEmail(accessToken: string): Observable<string> {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return this.http
      .get(
        'https://api.linkedin.com/v2/clientAwareMemberHandles?q=members&projection=(elements*(primary,type,handle~))',
        { headers }
      )
      .pipe(
        map((data: any) => {
          return data.elements[0]['handle~'].emailAddress;
        })
      );
  }

  getLinkedInAccessToken(code: string): Observable<any> {
    return this.http.post(
      this.apiClient.getUrlByApiName('linkedInLogin'),
      {
        code,
        redirectUri: environment.linkedin.redirectUri,
      },
      { responseType: 'text' }
    );
  }

  linkedInLogin(authCode: string) {
    if (sessionStorage.getItem('temp_auth_app_token')) sessionStorage.removeItem('temp_auth_app_token');

    return this.getLinkedInAccessToken(authCode).pipe(
      switchMap(accessToken => {
        this.loading.show();
        return this.socialLoginPipe(accessToken, ProviderEnum.LINKEDIN);
      }),
      catchError(err => {
        this.showError();
        return throwError(err);
      })
    );
  }

  // TODO: remove if new method
  oldAuthLogin(provider: any, providerName: ProviderEnum) {
    return this.afAuth
      .signInWithPopup(provider)
      .then(res => {
        this.afAuth.idToken.pipe(take(1)).subscribe((idToken: string) => {
          this.loading.show();
          if (sessionStorage.getItem('temp_auth_app_token')) {
            sessionStorage.removeItem('temp_auth_app_token');
          }
          this.socialLogin(idToken, providerName)
            .pipe(
              finalize(() => this.loading.hide()),
              catchError(err => {
                this.firebaseLogout();
                return throwError(err);
              })
            )
            .subscribe(async (socialLoginResponse: SocialLoginResponse) => {
              if (socialLoginResponse.jwtToken) {
                // Google Analytics
                // La social login è solo worker
                var user_type: 'worker';
                var method;
                if (providerName === ProviderEnum.APPLE) {
                  method = 'apple';
                } else if (providerName === ProviderEnum.FACEBOOK) {
                  method = 'facebook';
                } else if (providerName === ProviderEnum.GOOGLE) {
                  method = 'google';
                }
                this.gaService.gtag('login', {
                  user_type: user_type, // it must be either <company> or <worker>
                  user_id: socialLoginResponse.jwtToken.userUuid,
                  method: method, // it must be <facebook> or <google> or <email> or <linkedin> or <apple>
                });

                const token = this.getStrategy('email-password').createToken<NbAuthJWTToken>(
                  socialLoginResponse.jwtToken.token
                );

                this.tokenService.set(token).subscribe(() => {
                  const raw = this.parceler.wrap(token);
                  localStorage.setItem('auth_app_token', raw);
                  localStorage.setItem('refresh-token', socialLoginResponse.jwtToken.refreshToken);

                  this.userService.getUserRoleInfo().subscribe(user => {
                    this.userService.saveUserRoleInfo(user);
                    const announcementId = localStorage.getItem('announcementId');
                    if (announcementId) {
                      localStorage.removeItem('announcementId');
                      this.router.navigate(['private', 'candidato', 'cerca-annunci', 'search-detail', announcementId]);
                    } else {
                      this.userService.getUserWorkerStatus().subscribe(
                        workerStatus => {
                          this.redirectToApp(user, socialLoginResponse.jwtToken.userUuid, workerStatus);
                        },
                        error => {}
                      );
                    }
                  });
                });
              } else {
                this.dialogService
                  .open(new PolymorpheusComponent(PrivacyDialogComponent, this.injector), {
                    dismissible: false,
                    size: 'l',
                    label: this.privacyLabel,
                  })
                  .subscribe(async (outcome: any) => {
                    if (outcome) {
                      this.loading.show();
                      let terms = JSON.parse(localStorage.getItem('register_info_storage'));
                      this.socialRegistration(idToken, providerName, terms)
                        .pipe(finalize(() => this.loading.hide()))
                        .subscribe(() => {
                          this.router.navigate(['auth', 'login']);
                        });
                    }
                  });
              }
            });
        });
      })
      .catch(() => {
        this.showError();
      });
  }

  // Auth logic to run auth providers
  AuthLogin(provider: any, providerName: ProviderEnum) {
    return from(this.afAuth.signInWithPopup(provider)).pipe(
      switchMap(res => {
        return this.afAuth.idToken.pipe(
          take(1),
          switchMap(idToken => {
            this.loading.show();
            if (sessionStorage.getItem('temp_auth_app_token')) {
              sessionStorage.removeItem('temp_auth_app_token');
            }
            return this.socialLoginPipe(idToken, providerName);
          })
        );
      }),
      catchError(err => {
        this.showError();
        return throwError(err);
      })
    );
  }

  private _fetchUserRoleInfo(result: NbAuthResult): Observable<{ user: any; result: any }> {
    return this.userService.getUserRoleInfo().pipe(
      tap(user => {
        this.userService.saveUserRoleInfo(user);
      }),
      map(user => ({ result, user })),
      catchError(err =>
        observableOf({
          result: new NbAuthResult(false, err?.error, '', ['LOGIN.ERRORS.FETCH_USER']),
          user: null,
        })
      )
    );
  }

  firebaseLogout() {
    this.afAuth.signOut().then(() => {
      this.afAuth.currentUser.then((user: firebase.User | null) => {
        user?.delete();
      });
    });
  }

  registerCompany(formValues: RegisterCompanyRequest): Observable<RegisterCompanyResponse> {
    return this.apiClient.request('registerCompany', {
      ...formValues,
      referralData: {
        ...this.getReferralDataSessionStorage(ActionType.REGISTRATION),
      },
    });
  }

  registerWorker(formValues: RegisterWorkerRequest): Observable<RegisterWorkerResponse> {
    return this.apiClient.request('registerWorker', {
      ...formValues,
      referralData: {
        ...this.getReferralDataSessionStorage(ActionType.REGISTRATION),
      },
    });
  }

  registerWorkerProfile(formValues: RegisterWorkerProfileRequest): Observable<RegisterWorkerResponse> {
    let headers = new HttpHeaders();
    const authAppTokenString = sessionStorage.getItem('auth_app_token');
    let tokenObject = JSON.parse(authAppTokenString);
    if (!!tokenObject && !!tokenObject.value) {
      headers = headers.set('Authorization', `Bearer ${tokenObject.value}`);
    }
    return this.http.post<RegisterWorkerResponse>(this.apiClient.getUrlByApiName('registerWorkerProfile'), formValues, {
      headers,
    });
  }

  forgotPassword(email: string): Observable<any> {
    return this.apiClient.request('forgotPassword', { email });
  }

  customResetPassword(newPassword: string, resetPasswordToken: string): Observable<any> {
    return this.apiClient.request('resetPassword', {
      newPassword,
      resetPasswordToken,
    });
  }

  socialLogin(accessToken: string, provider: ProviderEnum) {
    return this.apiClient.request('socialLogin', {
      accessToken,
      provider,
      referralData: { ...this.getReferralDataSessionStorage(ActionType.LOGIN) },
    });
  }

  socialRegistration(accessToken: string, provider: ProviderEnum, terms: any) {
    // FIXME: create type for terms
    return this.apiClient.request('socialRegistrationLogin', {
      accessToken,
      provider,
      ...terms.privacyForm,
      referralData: {
        ...this.getReferralDataSessionStorage(ActionType.REGISTRATION),
      },
    });
  }

  socialLoginPipe(idToken: string, providerName: ProviderEnum) {
    return this.socialLogin(idToken, providerName).pipe(
      finalize(() => this.loading.hide()),
      catchError(err => {
        this.firebaseLogout();
        return throwError(err);
      }),
      switchMap((socialLoginResponse: SocialLoginResponse) => {
        if (socialLoginResponse && socialLoginResponse.jwtToken) {
          // Google Analytics
          // La social login è solo worker
          var user_type: 'worker';
          var method;
          if (providerName === ProviderEnum.APPLE) {
            method = 'apple';
          } else if (providerName === ProviderEnum.FACEBOOK) {
            method = 'facebook';
          } else if (providerName === ProviderEnum.GOOGLE) {
            method = 'google';
          } else if (providerName === ProviderEnum.LINKEDIN) {
            method = 'linkedin';
          }
          this.gaService.gtag('login', {
            user_type: user_type, // it must be either <company> or <worker>
            user_id: socialLoginResponse.jwtToken.userUuid,
            method: method, // it must be <facebook> or <google> or <email> or <linkedin> or <apple>
          });

          const token = this.getStrategy('email-password').createToken<NbAuthJWTToken>(
            socialLoginResponse.jwtToken.token
          );

          return this.tokenService.set(token).pipe(
            tap(() => {
              const raw = this.parceler.wrap(token);
              localStorage.setItem('auth_app_token', raw);
              localStorage.setItem('refresh-token', socialLoginResponse.jwtToken.refreshToken);
            }),
            switchMapTo(
              this.userService.getUserRoleInfo().pipe(
                tap(user => this.userService.saveUserRoleInfo(user)),
                switchMap(user => {
                  const announcementId = localStorage.getItem('announcementId');
                  if (announcementId) {
                    return this.router.navigate([
                      'private',
                      'candidato',
                      'cerca-annunci',
                      'search-detail',
                      announcementId,
                    ]);
                  }
                  return forkJoin([of(this.userService.userRoleInfo), this.userService.getUserWorkerStatus()]).pipe(
                    tap(([userRoleInfo, workerStatus]) => {
                      this.redirectToApp(user, socialLoginResponse.jwtToken.userUuid, workerStatus);
                    })
                  );
                })
              )
            )
          );
        } else {
          return this.dialogService
            .open(new PolymorpheusComponent(PrivacyDialogComponent, this.injector), {
              dismissible: false,
              size: 'l',
              label: this.privacyLabel,
            })
            .pipe(
              filter((outcome: any) => !!outcome),
              switchMap(() => {
                this.loading.show();
                let terms = JSON.parse(localStorage.getItem('register_info_storage'));
                return this.socialRegistration(idToken, providerName, terms).pipe(
                  tap(() => this.loading.hide()),
                  switchMapTo(this.socialLoginPipe(idToken, providerName))
                );
              })
            );
        }
      }),
      catchError(err => {
        this.showError();
        return throwError(err);
      })
    );
  }

  getStrategyByName(name: string) {
    return this.getStrategy(name);
  }

  redirectToApp(user, userUuid, status = null) {
    if (!!status && status.status === WorkerStatus.REGISTERED) {
      sessionStorage.setItem('auth_app_token', localStorage.getItem('auth_app_token'));
      sessionStorage.setItem('refresh-token', localStorage.getItem('refresh-token'));
      sessionStorage.setItem('user-uuid', userUuid);
      localStorage.removeItem('auth_app_token');
      localStorage.removeItem('refresh-token');
      this.registerService.clear();
      if (sessionStorage.getItem('temp_auth_app_token')) {
        sessionStorage.removeItem('temp_auth_app_token');
      }
      if (user.role === RoleType.WORKER) {
        this.router.navigate(['auth', 'register', 'worker-completion']);
      } else {
        this.router.navigate(['auth', 'register', 'company-completion']);
      }
    } else {
      /**
       * If sessionStorage contains announcementId, the user has visited FV to reach an announcement detail
       * To perform "Application" the user should be logged in
       * Now the user can be redirected to the private page where the user can apply
       */
      const announcementId = localStorage.getItem('announcementId');
      if (announcementId && user.role === RoleType.WORKER) {
        localStorage.removeItem('announcementId');
        this.router.navigate(['private', 'candidato', 'cerca-annunci', 'search-detail', announcementId]);
      } else {
        if (user.role == RoleType.DELEGATE) {
          const userRole = 'delegate';
          this.router.navigate(['private', role2route(user.role)], {
            queryParams: { loggedin: userRole },
          });
        } else {
          const userRole = user.role == RoleType.WORKER ? 'worker' : 'company';
          this.router.navigate(['private', role2route(user.role)], {
            queryParams: { loggedin: userRole },
          });
        }
      }
    }
  }

  setReferralDataSessionStorage(paramMap: ParamMap) {
    paramMap.keys.forEach(key => {
      const param = referralQueryParams.find(referralParam => referralParam.queryParamKey === key);
      param && sessionStorage.setItem(param.sessionStorageKey, paramMap.get(key));
    });
  }

  getReferralDataSessionStorage(actionType: ActionType) {
    let referralData: ReferralData = Object.keys(sessionStorage).reduce((obj, key) => {
      if (referralSessionStorageKeys.includes(key)) obj[key] = sessionStorage.getItem(key);
      return obj;
    }, {});
    referralData = {
      ...referralData,
      is_login: actionType,
    };
    return referralData;
  }

  updateDeviceToken(userUuid: string, deviceToken: string) {
    return this.apiClient.request('updateDeviceToken', {
      userUuid,
      deviceToken,
    });
  }

  private showError() {
    this.alert.error({ header: 'Errore', body: "Si è verificato un errore durante l'operazione" });
  }
}
