import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import {
  AppUser,
  AuthProvider,
  IsLoginType,
  LoginBody,
  LoginResponse,
  mapRoleToRoute,
  ReferralData,
  RoleType,
  SocialLoginResponse,
} from '@common/models/auth';
import { Company } from '@common/models/company';
import { Delegate } from '@common/models/delegate';
import { Worker } from '@common/models/worker';
import { SelectOption } from '@shared/form';
import { OAuthProvider } from 'firebase/auth';
import { BehaviorSubject, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppStorageService } from './app-storage.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseApiService {
  private user = new BehaviorSubject<AppUser>(null);
  private profile = new BehaviorSubject<Company | Worker | Delegate>(null);

  constructor(
    protected http: HttpClient,
    private router: Router,
    public oAuth: AngularFireAuth,
    protected storage: AppStorageService
  ) {
    super(http, storage);
    this.setStoredUser();
  }

  get user$() {
    return this.user.asObservable();
  }

  get profile$() {
    return this.profile.asObservable();
  }

  socialRegister(accessToken: string, provider: AuthProvider, privacy: any) {
    const referralData = this.getReferralData(IsLoginType.FALSE);
    return this.post<SocialLoginResponse>({
      endpoint: `${this.identityPath}/api/register/workerSocial`,
      body: {
        accessToken,
        provider,
        ...privacy,
        referralData,
      },
      isProtected: false,
    }).pipe(switchMap(() => this.socialLogin(accessToken, provider)));
  }

  login(body: LoginBody) {
    body.referralData = this.getReferralData(IsLoginType.TRUE);
    return this.post<LoginResponse>({ endpoint: `${this.identityPath}/api/login`, body, isProtected: false }).pipe(
      tap(resp => {
        this.handleLogin(resp);
      })
    );
  }

  socialLogin(accessToken: string, provider: AuthProvider) {
    const referralData = this.getReferralData(IsLoginType.TRUE);
    return this.post<SocialLoginResponse>({
      endpoint: `${this.identityPath}/api/social-login`,
      body: {
        accessToken,
        provider,
        referralData,
      },
      isProtected: false,
    }).pipe(
      map(resp => ({ ...resp, accessToken })),
      tap(resp => {
        if (resp?.jwtToken) {
          this.handleLogin(resp?.jwtToken);
        }
      })
    );
  }

  oauthLogin(provider: SelectOption<AuthProvider>) {
    const oauthProvider = new OAuthProvider(`${provider.des}.com`);
    oauthProvider.addScope('profile');
    oauthProvider.addScope('email');

    return from(this.oAuth.signInWithPopup(oauthProvider)).pipe(
      switchMap(() => this.oAuth.idToken),
      switchMap(token => this.socialLogin(token, provider.cod))
    );
  }

  impersonate(id: number) {
    return this.post<LoginResponse>({ endpoint: `${this.delegatePath}/impersonations/clients/${id}/` }).pipe(
      tap(resp => {
        this.loginSuccess(resp);
      })
    );
  }

  deimpersonate() {
    return this.post<LoginResponse>({ endpoint: `${this.delegatePath}/impersonations/pop/` }).pipe(
      tap(resp => {
        this.loginSuccess(resp);
      })
    );
  }

  refreshToken() {
    return this.post<LoginResponse>({
      endpoint: `${this.identityPath}/api/refresh-token`,
      body: {
        refreshToken: this.storage.getRefreshToken(),
      },
      isProtected: false,
    }).pipe(
      tap(resp => {
        this.loginSuccess(resp);
      })
    );
  }

  setUser(user: AppUser) {
    this.user.next(user);
  }

  setProfile(profile: Company | Worker | Delegate) {
    this.profile.next(profile);
  }

  isAuthorized(role: RoleType) {
    const user = this.user.getValue();
    return user && user.roles.includes(role);
  }

  isAuthenticated() {
    return !!this.user.getValue();
  }

  logout() {
    this.setUser(null);
    this.setProfile(null);
    this.storage.clearLocal();
    this.oAuth.signOut();
    this.router.navigate(['/']);
  }

  private handleLogin(resp: LoginResponse) {
    if (resp.token) {
      this.loginSuccess(resp);
    } else if (resp.otp) {
      this.otpRequested(resp);
    }
  }

  private loginSuccess(resp: LoginResponse) {
    const user = this.parseToken(resp.token);

    this.storage.setAccessToken(resp.token);
    this.storage.setRefreshToken(resp.refreshToken);
    this.storage.setAppUser(user);
    this.setUser(user);

    if (!user.impersonator) {
      this.redirect(user.roles[0] as RoleType);
    }
  }

  private otpRequested(resp: LoginResponse) {
    console.log(resp);
  }

  private parseToken(token: string): AppUser {
    try {
      const res = JSON.parse(atob(token.split('.')[1]));

      let impersonator = null;

      if (res.impersonator) {
        impersonator = {
          userId: res.impersonator.sub,
          username: res.impersonator.username,
        };
      }

      return {
        userId: res.sub,
        username: res.username,
        roles: res.roles,
        impersonator,
      };
    } catch (e) {
      return null;
    }
  }

  private redirect(role: RoleType) {
    const route = mapRoleToRoute.get(role);
    this.router.navigate(['/', 'private', route]);
  }

  private setStoredUser() {
    const user = this.storage.getAppUser();
    if (user) {
      this.setUser(user);
    }
  }

  private getReferralData(isLogin: IsLoginType): ReferralData {
    const referals = this.storage.getReferralData();

    if (!referals) {
      return {
        is_login: isLogin,
      };
    }

    return { ...referals, is_login: isLogin };
  }
}
