import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class HeadService {
  private prefix: string = '| InPlace - Dal contatto al contratto';

  constructor(
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  setTitle(title: string) {
    this.title.setTitle(`${title} ${this.prefix}`);
  }

  setDesctiption(content: string) {
    this.meta.updateTag({ name: 'description', content });
  }

  setMetaTag(tag: MetaDefinition) {
    this.meta.addTag(tag);
  }

  addJsonDl(dataDl: any) {
    let script = this.doc.getElementById('json-dl-script') as HTMLScriptElement;

    if (!script) {
      script = this.doc.createElement('script');
      script.type = 'application/ld+json';
      script.id = 'json-dl-script';
    }

    script.innerHTML = JSON.stringify({
      '@context': 'https://schema.org/',
      author: {
        '@type': 'Company',
        name: 'InPlace',
      },
      ...dataDl,
    });

    this.doc.getElementsByTagName('head')[0].appendChild(script);
  }
}
