import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@common/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private auth: AuthService) {}

  canActivate(): boolean {
    return this.auth.isAuthenticated();
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.auth.isAuthorized(route.data.role);
  }
}
